.range-row {
    display: flex;
    min-height: 7.692%;
    height: 7.692%;
}

.range-cell {
    border: 1px solid black;
    min-width: 7.692%;
    width: 7.692%;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: white;
}

.outofrange {
    /* color: #fff;
    padding: 10px;
    display: inline-block;
    border-radius: 5px; */
    animation: blinkingBackground 2s infinite;
  }
  @keyframes blinkingBackground{
    0%		{ background-color: #10c018;}
    25%		{ background-color: #1056c0;}
    50%		{ background-color: #ef0a1a;}
    75%		{ background-color: #254878;}
    100%	{ background-color: #04a1d5;}
  }
